import { Auth0Provider } from '@auth0/auth0-react'
import { connect } from '@cerebral/react'
import { sequences, state } from 'cerebral'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import apiClient from '../lib/util/api-client'
import LoadingScreen from './elements/LoadingScreen'

const service = apiClient.service('info/tenant')

const { hostname } = window.location

const TenantLoader = connect(
  {
    setInvitationToken: sequences`account.setInvitationToken`,
    setTenant: sequences`account.setTenant`,
    tenant: state`account.tenant`,
  },
  ({ setInvitationToken, setTenant, tenant, children }) => {
    useEffect(() => {
      service.find({ query: { hostname } }).then((data) => {
        if (data) {
          setTenant({ tenant: data })
        }
      })
    }, [])

    const onRedirectCallback = (appState) => {
      if (appState && appState.invitationToken) {
        const decodedUriToken = decodeURIComponent(appState.invitationToken)
        const { invitationToken } = JSON.parse(decodedUriToken)
        setInvitationToken({ invitationToken })
      }
    }

    return tenant ? (
      <Auth0Provider
        domain={tenant.domain}
        clientId={tenant.clientId}
        authorizationParams={{ redirect_uri: window.location.origin + '/login', prompt: 'select_account' }}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    ) : (
      <LoadingScreen />
    )
  }
)

TenantLoader.propTypes = {
  children: PropTypes.node,
  setAccountSetting: PropTypes.func,
}

export default TenantLoader
