import { state } from 'cerebral'
import Color from 'color'
import {
  complement,
  compose,
  filter,
  find,
  flatten,
  fromPairs,
  groupBy,
  ifElse,
  indexBy,
  isNil,
  map,
  mapObjIndexed,
  path,
  pathOr,
  pluck,
  prop,
  propEq,
  sortBy,
  toPairs,
} from 'ramda'
import { camelize, capitalize } from 'underscore.string'

const createShortCode = (string) => {
  let caps = string.replace(/[^A-Z]+/g, '')
  if (caps.length < 2) {
    caps = string
  }
  return caps.slice(0, 2).toUpperCase()
}

const eventLabelsByType = compose(
  groupBy(prop('category')),
  map(({ label, color, ...rest }) => {
    return {
      ...rest,
      label,
      shortCode: createShortCode(label),
      color,
      textColor: Color(color).isDark() ? '#fff' : 'rgba(26, 25, 25, 1)', // Text color from theme.
      value: camelize(label, true),
    }
  })
)

export const packages = (get) => {
  const organization = get(state`account.organization`)
  const location = get(state`account.location`)
  const packages = location?.packages || organization?.packages || []
  return fromPairs(packages.map(({ slug }) => [slug, true]))
}

export const availableScreens = (get) => {
  const organization = get(state`account.organization`)
  const location = get(state`account.location`)
  const packages = location?.packages || organization?.packages || []
  const { screenLabels = {} } = organization || {}
  const setNameByTemplate = (screen) => ({ ...screen, name: screenLabels[screen.template]?.trim() || screen.name })
  const screens = sortBy(prop('name'), flatten(filter(Boolean, pluck('enablesScreens', packages))).map(setNameByTemplate))
  return indexBy(prop('_id'), screens)
}

export const organization = (get) => {
  const orgSlug = get(state`account.orgSlug`)
  const resorts = get(state`account.currentUser.resorts`) || []
  const resort = find(propEq(orgSlug, 'slug'), resorts) || resorts[0]
  return resort
}

const getComputedOrg = (get) => get(state`account.organization`)
export const labels = compose(prop('mobileLabel'), getComputedOrg)
export const sections = compose(prop('mobileSections'), getComputedOrg)
export const eventLocationCode = compose(path(['eventSettings', 'locationCode']), getComputedOrg)
export const eventLabels = compose(eventLabelsByType, pathOr([], ['eventSettings', 'eventLabels']), getComputedOrg)
export const eventLabelsMap = (get) => {
  const labelsByType = get(state`account.eventLabels`)
  return mapObjIndexed(indexBy(prop('_id')), labelsByType)
}

export const orgRoles = compose(prop('mobileRoles'), getComputedOrg)
export const orgRolesOptions = compose(
  sortBy(prop('value')),
  map(([key]) => ({ value: key, label: capitalize(key) })),
  filter(prop(1)),
  toPairs,
  orgRoles
)

// TODO: It seems this should use generateStats from alpine-common. The logic is duplicated.
const liftIsOpen = ifElse(prop('statusOverride'), propEq('open', 'statusOverride'), propEq('status', 'open'))
const trailShouldIncludeInCount = complement(propEq(false, 'includeInTrailCount'))
const trailIsOpen = ifElse(compose(isNil, path(['override', 'open'])), prop('open'), path(['override', 'open']))

const percent = (numerator, denominator) => (denominator ? Math.floor((numerator / denominator) * 100) : 0)

export const season = (get) => {
  const org = getComputedOrg(get)
  if (!org) {
    return
  }

  const values = get(state`account.organization.scrapedData.stats`) || {}
  const overrides = get(state`account.organization.overrideData.stats`) || {}
  const seasons = get(state`account.currentUser.seasons`)
  const season = find(propEq(org._id, 'resort'), seasons)
  const lifts = get(state`lifts.list.data`)
  const trails = get(state`trails.list.data`).filter(trailShouldIncludeInCount)

  // Populate stats for selected season. Used by snow stats.
  // TODO: It seems this should use generateStats from alpine-common. The logic is duplicated.
  if (season) {
    const lists = ['stats', 'secondaryStats', 'featureStats', 'hiddenStats']
    lists.forEach((listName) => {
      const list = season[listName] || []
      list.forEach((stat) => {
        const { slug } = stat
        if (stat.path?.length > 0) {
          const statPath = path(stat.path)
          const value = statPath(values)
          const override = statPath(overrides)
          if (!isNil(value)) {
            stat.value = value
          }
          if (!isNil(override)) {
            stat.override = override
          }
        } else if (slug === 'lifts-percent' || slug === 'lifts-count' || slug === 'lifts-total') {
          if (lifts.length === 0 || slug === 'lifts-total') {
            stat.value = lifts.length
          } else {
            const openCount = lifts.filter(liftIsOpen).length
            stat.value = slug === 'lifts-count' ? openCount : percent(openCount, lifts.length)
          }
        } else if (slug === 'trails-percent' || slug === 'trails-count' || slug === 'trails-total') {
          if (trails.length === 0 || slug === 'trails-total') {
            stat.value = trails.length
          } else {
            const openCount = trails.filter(trailIsOpen).length
            stat.value = slug === 'trails-count' ? openCount : percent(openCount, trails.length)
          }
        }
      })
    })
    // Special case for conditions.
    // TODO: Make a consistent pattern for this.
    season.conditionsOverride = overrides.conditions
  }

  return season
}
