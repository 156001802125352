import { connect } from '@cerebral/react'
import { Link, MenuItem } from '@mui/material'
import { props, sequences } from 'cerebral'
import React, { useCallback } from 'react'
import { useToggleState } from '../../../lib/util/hooks'
import { entityFromState } from '../../../modules/computed'
import { routePaths } from '../../../modules/route'
import CardList, { CardListItem } from '../../blocks/CardList'
import ConfirmDialog from '../../controls/ConfirmDialog'
import StatusText from '../../elements/StatusText'

const Card = connect(
  {
    remove: sequences`albums.remove`,
    togglePublished: sequences`albums.togglePublished`,
    item: entityFromState('albums', props`id`),
  },

  ({ item, remove, togglePublished }) => {
    const { _id: id, name, description, media = [], published } = item
    const [deleting, openDeleting, closeDeleting] = useToggleState(false)
    const confirmDelete = useCallback(() => remove({ id }), [id])

    const actions = [
      <MenuItem key="edit" component={Link} href={routePaths.toAlbum({ id })}>
        Edit album
      </MenuItem>,

      <MenuItem key="publish" component={Link} onClick={() => togglePublished({ id })}>
        {published ? 'Unpublish' : 'Publish'} album
      </MenuItem>,

      <MenuItem key="delete" onClick={openDeleting}>
        Delete album
      </MenuItem>,
    ].filter(Boolean)

    const status = (
      <StatusText component="span" variant="caption" color={published ? 'success' : 'error'}>
        {published ? 'Published' : 'Unpublished'}
      </StatusText>
    )

    return (
      <>
        <ConfirmDialog open={deleting} title={`Delete album`} onConfirm={confirmDelete} onClose={closeDeleting} confirmText="Delete">
          Are you sure you want to delete album <strong>{name}</strong>?
        </ConfirmDialog>
        <CardListItem
          item={item}
          title={name}
          body={description}
          media={media[0]}
          href={routePaths.toAlbum({ id })}
          status={status}
          actions={actions}
          dragSort
        />
      </>
    )
  }
)

const List = connect(
  {
    updateOrder: sequences`albums.updateOrder`,
  },
  ({ updateOrder }) => {
    return <CardList type="album" itemComponent={Card} createHref={routePaths.toAlbum({ id: 'create' })} onDragSort={updateOrder} />
  }
)

export default List
