import { props, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { isNumber } from 'ramda-adjunct'
import { showFeedback } from '../feedback/factories'
import { sequences as route } from '../route'
import * as actions from './actions'
import { saveEntities } from '../factories'

export const setUserChecked = [
  //
  set(state`account.checked`, true),
]

const postLoginSteps = [
  when(state`account.currentUser.canAccessCommandCenter`),
  {
    true: [
      when(state`account.redirectedFromPath`),
      {
        true: [
          //
          set(props`path`, state`account.redirectedFromPath`),
          set(state`account.redirectedFromPath`, null),
          route.toPath,
        ],
        false: [
          // Redirect to settings to enter address if location information is not already available.
          when(state`account.organization`, (organization) =>
            Boolean(organization?.timezone && isNumber(organization?.latitude) && isNumber(organization?.longitude))
          ),
          {
            true: route.toRoot,
            false: route.toSettings,
          },
        ],
      },
    ],
    false: [
      // No redirect, to allow association with local user.
    ],
  },
]

export const login = [
  //
  actions.authenticate,
  actions.setCurrentUser,
  actions.checkOrgSlug,
  setUserChecked,
  postLoginSteps,
]

export const loginLocal = [
  //
  set(state`account.checked`, false),
  actions.associateLocal,
  actions.setCurrentUser,
  setUserChecked,
  postLoginSteps,
]

export const reauthenticate = [
  //
  actions.reauthenticate,
  actions.setCurrentUser,
  setUserChecked,
]

export const signOut = [
  // Setting account.checked prevents automatically redirecting during signout.
  set(state`account.checked`, false),
  actions.signOut,
]

export const changeOrganization = [
  //
  route.toRoot,
  actions.changeOrganization,
]

export const changeLocation = [
  //
  route.toRoot,
  actions.changeLocation,
]

export const saveSettings = [
  //
  actions.saveSettings,
  saveEntities(),
  showFeedback({ title: 'Your settings have been saved', type: 'success' }),
  when(state`route.query.redirectTo`, (redirectTo) => redirectTo === 'contact-info'),
  {
    true: route.toContactInfoSettings,
    false: [],
  },
]

export const saveContactSettings = [
  //
  actions.saveSettings,
  showFeedback({ title: 'Contact info has been saved', type: 'success' }),
  route.toContactInfoSettings,
]

export const setInvitationToken = [
  //
  actions.setInvitationToken,
]

export const setTenant = [
  //
  actions.setTenant,
]
