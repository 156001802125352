import { connect } from '@cerebral/react'
import { Box, Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material'
import { Add, Delete, Refresh, ContentPaste } from '@mui/icons-material'
import { sequences, state } from 'cerebral'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect } from 'react'
import { dasherize, trim, capitalize } from 'underscore.string'
import brands from '../../../../shared/brands'
import utils from '../../../../shared/utils'
import brand from '../../../lib/util/brand'
import QrCode from '../../blocks/QrCode'
import ActionHeader from '../../elements/ActionHeader'
import Notice from '../../elements/Notice'
import ConfirmDialog from '../../controls/ConfirmDialog'
import { useToggleState } from '../../../lib/util/hooks'
import ThemedButton from '../../controls/ThemedButton'

const { getDomain, label = 'community' } = brands
const { shortid } = utils

const isQuiltt = brand.name === 'Quiltt'

const Organization = connect(
  {
    organization: state`account.organization`,
    mobileRoleRequestToken: state`account.organization.mobileRoleRequestToken`,
    location: state`account.location`,
    getRecipientCount: sequences`notifications.getRecipientCount`,
    recipientCount: state`notifications.recipientCount`,
    saveSettings: sequences`account.saveSettings`,
    timezone: state`account.currentUser.timezone`,
    showFeedback: sequences`feedback.showFeedback`,
  },

  ({ organization, mobileRoleRequestToken, location, getRecipientCount, recipientCount, saveSettings, timezone, showFeedback }) => {
    const domain = getDomain(organization)
    const code = organization?.mobileAccessCode?.code
    const installPath = code ? `org/${code}` : 'appinstall'
    const installUrl = (domain && `https://${domain}/${installPath}`) || ''
    const roleRequestUrl = (domain && `https://${domain}/requestRole/${code || '__'}/${mobileRoleRequestToken}`) || ''
    const baseName = trim(dasherize(organization?.name || 'download'), ' -')
    const installDownload = (installUrl && `${baseName}.png`) || ''
    const roleRequestDownload = (roleRequestUrl && `${baseName}-roles.png`) || ''

    const dateMailArrivedAt = DateTime.fromISO(organization?.mailArrivedAt).setZone(timezone)
    const today = DateTime.now().setZone(timezone)

    useEffect(() => {
      if (organization && !location) {
        getRecipientCount({ audience: 'all' })
      }
    }, [organization, location])

    const setMailArrivedAt = useCallback(({ target: { checked } }) => saveSettings({ values: { mailArrivedAt: checked ? new Date() : null } }), [saveSettings])

    const [generateConfirmVisible, openGenerateConfirm, closeGenerateConfirm] = useToggleState()
    const generateRoleRequestToken = useCallback(() => saveSettings({ values: { mobileRoleRequestToken: shortid() } }), [saveSettings])
    const [clearConfirmVisible, openClearConfirm, closeClearConfirm] = useToggleState()
    const clearRoleRequestToken = useCallback(() => saveSettings({ values: { mobileRoleRequestToken: '' } }), [saveSettings])
    const copyMobileAccessCode = useCallback(() => {
      navigator.clipboard
        .writeText(code)
        .then(() => showFeedback({ title: 'Copied to clipboard.', type: 'success' }))
        .catch(() => showFeedback({ title: 'There was a problem copying the code.', type: 'error' }))
    }, [code])

    return (
      <Box>
        <ActionHeader title="Overview" section="Organization" />
        {!location && (
          <>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Notice>
                  <Typography variant="h2">{recipientCount}</Typography>
                  <Typography variant="body2">Total app users</Typography>
                </Notice>
                <Box my={6} />
              </Grid>
              <Grid item md={6} xs={12}>
                {isQuiltt && (
                  <Box mt={1} mb={2}>
                    <Typography variant="h3" gutterBottom>
                      Mail Status
                    </Typography>
                    <FormControlLabel
                      control={<Checkbox checked={Boolean(today.hasSame(dateMailArrivedAt, 'day'))} onChange={setMailArrivedAt} />}
                      label="Mail is here?"
                    />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Box mb={4}>
                  <Typography variant="h3" gutterBottom>
                    {capitalize(label)} Code
                  </Typography>
                  <Box mt={2}>
                    <Notice>
                      Here is your {label} access code. Share this code with residents, family, and staff who are interested in connecting with your {label}
                      through the Quiltt mobile app or on the web. This will give them access to all the content, events, and features specifically tailored to
                      your {label}.
                    </Notice>
                  </Box>
                  <ThemedButton startIcon={<ContentPaste />} variant="outlined" onClick={copyMobileAccessCode}>
                    {code}
                  </ThemedButton>
                </Box>
                <Typography variant="h3" gutterBottom>
                  Mobile App Install Link
                </Typography>
                <Link href={installUrl}>{installUrl}</Link>
                <Box style={{ maxWidth: 400 }} mb={6}>
                  <QrCode code={installUrl} download={installDownload} width={1200} />
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant="h3" gutterBottom>
                  Request Role Link
                </Typography>

                {mobileRoleRequestToken ? (
                  <>
                    <ConfirmDialog open={generateConfirmVisible} title="Generate new link?" onConfirm={generateRoleRequestToken} onClose={closeGenerateConfirm}>
                      Regenerating the request link token will invalidate your existing link, and you will need to share the new link with users who need to
                      request a role.
                    </ConfirmDialog>

                    <ConfirmDialog open={clearConfirmVisible} title="Remote link?" onConfirm={clearRoleRequestToken} onClose={closeClearConfirm}>
                      Removing the request link token will invalidate your existing link, and no users will be able to request a role.
                    </ConfirmDialog>

                    <Box display="flex" flexDirection="row" mb={2}>
                      <Box mr={1}>
                        <Button startIcon={<Refresh />} variant="outlined" size="small" onClick={openGenerateConfirm}>
                          Regenerate role request link
                        </Button>
                      </Box>
                      <Button startIcon={<Delete />} variant="outlined" size="small" onClick={openClearConfirm}>
                        Remove role request link
                      </Button>
                    </Box>

                    <Link href={roleRequestUrl}>{roleRequestUrl}</Link>
                    <Box style={{ maxWidth: 400 }} mb={6}>
                      <QrCode code={roleRequestUrl} download={roleRequestDownload} width={1200} />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box mt={2}>
                      <Notice>
                        <p>
                          If you create a role request link, you can provide your users with a link or QR code that will let them request the appropriate role
                          for your {label}.
                        </p>
                        <p>New role requests will be available for you to review in the People section.</p>
                      </Notice>
                      <Button startIcon={<Add />} color="primary" size="small" onClick={generateRoleRequestToken}>
                        Create role request link
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
            <Box my={4} />
          </>
        )}
      </Box>
    )
  }
)

export default Organization
